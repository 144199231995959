<template>
	<div class="gg-container" v-loading.fullscreen.lock="fullscreenLoading">
		<el-form :model="info" :rules="rules" ref="ruleForm" class="demo-ruleForm">
			<div class="header">
				<h2 class="title" v-if="type =='view'">
					{{ info.doc_name }}--{{ info.organization }}--{{ info.depart_name }}--{{ doctorTypeTitle }}
				</h2>

				<div class="search-container" v-else>
					<div class="search-container-fn-input">
						<el-form-item label="姓名：" prop="doc_name">
							<el-input placeholder="请输入姓名" v-if="type!=='view'" v-model="info.doc_name" size="small">
							</el-input>
							<span v-else>{{ info.doc_name }}</span>
						</el-form-item>
					</div>
				</div>
				<div class="elUpload docPhoto">
					<el-upload v-if="type!=='view'" class="avatar-uploader" :disabled="type=='view'"
						:action="actionPath" :data="qiniuData" :show-file-list="false"
						:on-success="uploadDocPhotoSuccess" :before-upload="beforeAvatarUpload">
						<img v-if="info.doc_photo" :src="info.doc_photo | filterCDNUrl" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<el-image class="avatar abc" :z-index="9999" :src="info.doc_photo | filterCDNUrl"
						:preview-src-list="[filterCDNUrl(info.doc_photo)] " v-else>
					</el-image>

				</div>
			</div>
			<div class="gg-grid">
				<div class="title">
					<h3>基本信息</h3>
					<!--          <em class="text-success">-->
					<!--            <i class="el-icon-success"></i>-->
					<!--            已认证-->
					<!--          </em>-->
				</div>

				<el-row :gutter="20">
					<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6"
						v-if="type=='view'">
						<div class="search-container">
							<div class="search-container-fn-input">
								<el-form-item label="编号：" prop="doc_name">
									<span>{{ info.id }}</span>
								</el-form-item>
							</div>
						</div>
					</el-col>

					<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
						<div class="search-container">
							<div class="search-container-fn-input">
								<el-form-item label="手机号码：" prop="doc_tel">
									<el-input placeholder="请输入手机号码" maxlength="11" v-if="type!=='view'"
										v-model="info.doc_tel" size="small"></el-input>
									<span v-else>{{ info.doc_tel }}</span>
								</el-form-item>
							</div>
						</div>
					</el-col>
					<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
						<div class="search-container">
							<div class="search-container-fn-input">
								<el-form-item label="第一执业机构：" prop="organization_id">
									<el-select v-if="type!=='view'" v-model="info.organization" size="mini" filterable
										remote reserve-keyword placeholder="请输入关键词" @change='orgSelectChange'
										:remote-method="remoteMethod" :loading="selectLoading">
										<el-option v-for="item in orgOptions" :key="item.org_id" :label="item.org_name"
											:value="item.org_id">
										</el-option>
									</el-select>
									<span v-else>{{ info.organization }}</span>
								</el-form-item>
							</div>
						</div>
					</el-col>

					<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
						<div class="search-container">
							<div class="search-container-fn-input">
								<el-form-item label="性别：" prop="ge_code">
									<el-select v-if="type!=='view'" v-model="info.ge_code" clearable placeholder="请选择"
										size="small">
										<el-option v-for="item in doctorDic.gender" :key="item.value"
											:label="item.label" :value="item.value">
										</el-option>
									</el-select>
									<span v-else>{{ info.ge_name }}</span>
								</el-form-item>
							</div>
						</div>
					</el-col>
					<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
						<div class="search-container">
							<div class="search-container-fn-input">
								<el-form-item label="民族：" prop="nation_code">
									<el-select v-if="type!=='view'" v-model="info.nation_code" clearable
										placeholder="请选择" size="small">
										<el-option v-for="item in doctorDic.nation" :key="item.value"
											:label="item.label" :value="item.value">
										</el-option>
									</el-select>
									<span v-else>{{ info.nation_name }}</span>
								</el-form-item>
							</div>
						</div>
					</el-col>
					<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
						<div class="search-container">
							<div class="search-container-fn-input">
								<el-form-item label="出生日期：" prop="doc_birthdate">
									<el-date-picker v-model="info.doc_birthdate" type="date" size="small"
										v-if="type!=='view'" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
										placeholder="选择出生日期">
									</el-date-picker>
									<span v-else>{{ info.doc_birthdate }}</span>
								</el-form-item>
							</div>
						</div>
					</el-col>
					<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
						<div class="search-container">
							<div class="search-container-fn-input">
								<el-form-item label="学历：" prop="doc_edu">
									<el-select v-model="info.doc_edu" clearable placeholder="请选择" size="small"
										v-if="type!=='view'">
										<el-option v-for="item in eduList" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
									<span v-else>{{ info.doc_edu }}</span>
								</el-form-item>
							</div>
						</div>
					</el-col>
					<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
						<div class="search-container">
							<div class="search-container-fn-input">
								<el-form-item label="毕业院校：" prop="doc_university">
									<el-input placeholder="请输入毕业院校" size="small" v-model=" info.doc_university "
										v-if="type!=='view'"></el-input>
									<span v-else>{{ info.doc_university }}</span>
								</el-form-item>
							</div>
						</div>
					</el-col>

					<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6"
						v-if="type=='view'">
						<div class="search-container">
							<div class="search-container-fn-input">
								<el-form-item label="注册时间：" prop="created_at">
									<span>{{ info.created_at }}</span>
								</el-form-item>
							</div>
						</div>
					</el-col>
					<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6"
						v-if="type!=='view'">
						<div class="search-container">
							<div class="search-container-fn-input">
								<el-form-item label="省市区：" prop="district_code">
									<el-cascader size="small" :options="pcaOptions" v-model="info.district_code"
										@change="handleChange">
									</el-cascader>
								</el-form-item>
							</div>
						</div>
					</el-col>

					<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
						<div class="search-container">
							<div class="search-container-fn-input">
								<el-form-item label="详细地址：" prop="doc_address">
									<el-input placeholder="请输入详细地址" size="small" v-model="info.doc_address"
										v-if="type!=='view'"></el-input>
									<span
										v-else>{{info.province_name}}{{info.city_name}}{{info.district_name}}{{ info.doc_address }}</span>
								</el-form-item>
							</div>
						</div>
					</el-col>

					<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
						<div class="search-container">
							<div class="search-container-fn-input">
								<el-form-item label="邮编：" prop="doc_postal_code">
									<el-input placeholder="请输入正确的邮编" size="small" maxlengt="7"
										v-model="info.doc_postal_code" v-if="type!=='view'"></el-input>
									<span v-else>{{info.doc_postal_code}}</span>
								</el-form-item>
							</div>
						</div>
					</el-col>


				</el-row>
			</div>
			<div class="gg-grid">
				<el-tabs v-model="activeTab">
					<el-tab-pane label="证件及合同" name="first">
						<div class="gg-grid">
							<div class="title">
								<h3>身份证</h3>
							</div>
							<el-row :gutter="20">
								<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
									<div class="search-container">
										<div class="search-container-fn-input">
											<el-form-item label="身份证号：" prop="id_card">
												<el-input placeholder="请输入身份证号" maxlength="18" size="small"
													v-model="info.id_card" v-if="type!=='view'"></el-input>
												<span v-else>{{ info.id_card }}</span>
											</el-form-item>
										</div>
									</div>
									<div class="elUpload">

										<i v-if="type!=='view' && info.id_card_list.a" @click="info.id_card_list.a=''"
											class="el-icon-error del"></i>
										<el-upload v-if="type!=='view'" class="avatar-uploader" :disabled="type=='view'"
											:action="actionPath" :data="qiniuData" :show-file-list="false"
											:on-success="uploadIDCardA" :before-upload="beforeAvatarUpload">
											<img v-if="info.id_card_list.a" :src="info.id_card_list.a | filterCDNUrl"
												class="avatar">
											<i v-else class="el-icon-plus avatar-uploader-icon"></i>
										</el-upload>
										<el-image v-else :z-index="9999" :src="info.id_card_list.a | filterCDNUrl"
											:preview-src-list="[ filterCDNUrl(info.id_card_list.a )  ]" class="avatar">
										</el-image>

										<p>身份证头像面</p>
									</div>
								</el-col>

								<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
									<div class="search-container">
										<div class="search-container-fn-input">
											<el-form-item label="证件有效期：" prop="doc_birthdate">
												<el-date-picker v-model="info.id_expire_date" type="date" size="small"
													v-if="type!=='view'" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
													placeholder="请证件有效期">
												</el-date-picker>
												<span v-else>{{ info.id_expire_date }}</span>
											</el-form-item>
										</div>
									</div>
									<div class="elUpload">
										<i v-if="type!=='view' && info.id_card_list.b" @click="info.id_card_list.b=''"
											class="el-icon-error del"></i>

										<el-upload v-if="type!=='view'" class="avatar-uploader" :disabled="type=='view'"
											:action="actionPath" :data="qiniuData" :show-file-list="false"
											:on-success="uploadIDCardB" :before-upload="beforeAvatarUpload">
											<img v-if="info.id_card_list.b" :src="info.id_card_list.b | filterCDNUrl"
												class="avatar">
											<i v-else class="el-icon-plus avatar-uploader-icon"></i>
										</el-upload>
										<el-image v-else :src="info.id_card_list.b | filterCDNUrl" :z-index="9999"
											:preview-src-list="[ filterCDNUrl(info.id_card_list.b )  ]" class="avatar">
										</el-image>
										<p>身份证国徽面</p>
									</div>
								</el-col>
							</el-row>
						</div>

						<div class="gg-grid">
							<div class="title">
								<h3>执业证</h3>
							</div>
							<el-row :gutter="20">
								<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
									<div class="search-container">
										<div class="search-container-fn-input">
											<el-form-item label="执业证号：" prop="prac_no">
												<el-input placeholder="请输入执业证号" v-model="info.prac_no" size="small"
													v-if="type!=='view'"></el-input>
												<span v-else>{{ info.prac_no }}</span>
											</el-form-item>
										</div>
									</div>
								</el-col>



								<!-- <el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
                  <div class="search-container">
                    <div class="search-container-fn-input">
                      <el-form-item label="证件有效期：" prop="prac_sited_date_end">
                        <el-date-picker
                            v-model="info.prac_sited_date_end"
                            type="date"
                            size="small"
                            v-if="type!=='view'"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            placeholder="请证件有效期">
                        </el-date-picker>
                        <span v-else>{{ info.prac_sited_date_end }}</span>
                      </el-form-item>
                    </div>
                  </div>
                </el-col> -->
								<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">

									<div class="search-container">
										<div class="search-container-fn-input">
											<el-form-item label="执业级别：" prop="prac_level">
												<el-select v-if="type!=='view'" v-model="info.prac_level" clearable
													placeholder="请选择" size="small">
													<el-option v-for="item in doctorDic.prac_level" :key="item.value"
														:label="item.label" :value="item.value">
													</el-option>
												</el-select>
												<span v-else>{{ info.prac_level_name }}</span>
											</el-form-item>
										</div>
									</div>
								</el-col>


							</el-row>
							<el-row :gutter="20">
								<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
									<div class="search-container">
										<div class="search-container-fn-input">

											<el-form-item label="执业类别：" prop="prac_level">
												<el-select v-if="type!=='view'" v-model="info.prac_type" clearable
													placeholder="请选择" size="small">
													<el-option v-for="item in doctorDic.prac_type" :key="item.value"
														:label="item.label" :value="item.value">
													</el-option>
												</el-select>
												<span v-else>{{ info.prac_type_name }}</span>
											</el-form-item>
										</div>
									</div>
								</el-col>
								<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
									<div class="search-container">
										<div class="search-container-fn-input">
											<el-form-item label="执业范围：" prop="prac_level">
												<el-select v-if="type!=='view'" v-model="info.prac_scope_approval"
													clearable multiple placeholder="请选择" size="small">
													<el-option v-for="item in doctorDic.prac_scope" :key="item.value"
														:label="item.label" :value="item.value">
													</el-option>
												</el-select>
												<div v-else>
													<span v-for="(span,index) in info.prac_scope_approval_name"
														:key="index">{{ span }} </span>
												</div>
											</el-form-item>
										</div>
									</div>
								</el-col>
								<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
									<div class="search-container">
										<div class="search-container-fn-input">
											<el-form-item label="发证日期：" prop="prac_rec_date">
												<el-date-picker v-model="info.prac_rec_date" type="date" size="small"
													v-if="type!=='view'" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
													placeholder="请选择发证日期">
												</el-date-picker>
												<span v-else>{{ info.prac_rec_date }}</span>
											</el-form-item>
										</div>
									</div>
								</el-col>

							</el-row>


							<el-row :gutter="20">
								<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
									<div class="elUpload">
										<i v-if="type!=='view' && info.cert_doc_prac_list.code_page"
											@click="info.cert_doc_prac_list.code_page=''" class="el-icon-error del"></i>

										<el-upload v-if="type!=='view'" class="avatar-uploader" :disabled="type=='view'"
											:action="actionPath" :data="qiniuData" :show-file-list="false"
											:on-success="uploadCodePage" :before-upload="beforeAvatarUpload">
											<img v-if="info.cert_doc_prac_list.code_page"
												:src="info.cert_doc_prac_list.code_page  | filterCDNUrl" class="avatar">
											<i v-else class="el-icon-plus avatar-uploader-icon"></i>
										</el-upload>
										<el-image v-else :src="info.cert_doc_prac_list.code_page  | filterCDNUrl"
											:z-index="9999"
											:preview-src-list="[ filterCDNUrl(info.cert_doc_prac_list.code_page )  ]"
											class="avatar">
										</el-image>
										<p>执业证编号页</p>
									</div>
								</el-col>
								<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
									<div class="elUpload">
										<i v-if="type!=='view' && info.cert_doc_prac_list.info_page"
											@click="info.cert_doc_prac_list.info_page=''" class="el-icon-error del"></i>

										<el-upload v-if="type!=='view'" class="avatar-uploader" :disabled="type=='view'"
											:action="actionPath" :data="qiniuData" :show-file-list="false"
											:on-success="uploadInfoPage" :before-upload="beforeAvatarUpload">
											<img v-if="info.cert_doc_prac_list.info_page"
												:src="info.cert_doc_prac_list.info_page | filterCDNUrl" class="avatar">
											<i v-else class="el-icon-plus avatar-uploader-icon"></i>
										</el-upload>
										<el-image v-else :src="info.cert_doc_prac_list.info_page | filterCDNUrl"
											:z-index="9999"
											:preview-src-list="[ filterCDNUrl(info.cert_doc_prac_list.info_page )  ]"
											class="avatar">
										</el-image>
										<p>执业证个人信息页</p>
									</div>
								</el-col>
							</el-row>
						</div>
						<div class="gg-grid">
							<div class="title">
								<h3>资格证</h3>
							</div>
							<el-row :gutter="20">
								<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
									<div class="search-container">
										<div class="search-container-fn-input">
											<el-form-item label="资格证号：" prop="cert_no">
												<el-input placeholder="请输入资格证号" v-model="info.cert_no" size="small"
													v-if="type!=='view'"></el-input>
												<span v-else>{{ info.cert_no }}</span>
											</el-form-item>
										</div>
									</div>
									<div class="elUpload">
										<i v-if="type!=='view' && info.doc_cert_list.code_page"
											@click="info.doc_cert_list.code_page=''" class="el-icon-error del"></i>

										<el-upload v-if="type!=='view'" class="avatar-uploader" :disabled="type=='view'"
											:action="actionPath" :data="qiniuData" :show-file-list="false"
											:on-success="uploadZGCodePage" :before-upload="beforeAvatarUpload">
											<img v-if="info.doc_cert_list.code_page"
												:src="info.doc_cert_list.code_page | filterCDNUrl" class="avatar">
											<i v-else class="el-icon-plus avatar-uploader-icon"></i>
										</el-upload>
										<el-image v-else :src="info.doc_cert_list.code_page | filterCDNUrl"
											:z-index="9999"
											:preview-src-list="[ filterCDNUrl(info.doc_cert_list.code_page )  ]"
											class="avatar"></el-image>
										<p>资格证编号页</p>
									</div>
								</el-col>

								<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
									<div class="search-container">
										<div class="search-container-fn-input">
											<el-form-item label="证件有效期：">
												<el-date-picker v-model="info.cert_expire_date" type="date" size="small"
													v-if="type!=='view'" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
													placeholder="请选择发证日期">
												</el-date-picker>
												<span v-else>{{ info.name }}</span>
											</el-form-item>
										</div>
									</div>
									<div class="elUpload">
										<i v-if="type!=='view' && info.doc_cert_list.info_page"
											@click="info.doc_cert_list.info_page=''" class="el-icon-error del"></i>
										<el-upload v-if="type!=='view'" class="avatar-uploader" :disabled="type=='view'"
											:action="actionPath" :data="qiniuData" :show-file-list="false"
											:on-success="uploadZGInfoPage" :before-upload="beforeAvatarUpload">
											<img v-if="info.doc_cert_list.info_page"
												:src="info.doc_cert_list.info_page | filterCDNUrl" class="avatar">
											<i v-else class="el-icon-plus avatar-uploader-icon"></i>
										</el-upload>
										<el-image v-else :src="info.doc_cert_list.info_page | filterCDNUrl"
											:z-index="9999"
											:preview-src-list="[ filterCDNUrl(info.doc_cert_list.info_page )  ]"
											class="avatar"></el-image>

										<p>资格证个人信息页</p>
									</div>
								</el-col>
								<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
									<div class="search-container">
										<div class="search-container-fn-input">
											<el-form-item label="发证日期：" prop="cert_rec_date">
												<el-date-picker v-model="info.cert_rec_date" type="date" size="small"
													v-if="type!=='view'" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
													placeholder="请选择发证日期">
												</el-date-picker>
												<span v-else>{{ info.cert_rec_date }}</span>
											</el-form-item>
										</div>
									</div>
								</el-col>
							</el-row>
						</div>
						<div class="gg-grid">
							<div class="title">
								<h3>职称</h3>
							</div>
							<el-row :gutter="20">
								<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
									<div class="search-container">
										<div class="search-container-fn-input">
											<el-form-item label="职称证号：" prop="title_no">
												<el-input placeholder="请输入职称证号" v-model="info.title_no" size="small"
													v-if="type!=='view'"></el-input>
												<span v-else>{{ info.title_no }}</span>
											</el-form-item>
										</div>
									</div>
								</el-col>
								<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
									<div class="search-container">
										<div class="search-container-fn-input">
											<el-form-item label="职称：" prop="title_code">
												<el-select v-if="type!=='view'" v-model="info.title_code" clearable
													placeholder="请选择" size="small">
													<el-option v-for="item in doctorDic.doctor_title" :key="item.value"
														:label="item.label" :value="item.value">
													</el-option>
												</el-select>
												<span v-else>{{ info.title_name }}</span>
											</el-form-item>
										</div>
									</div>
								</el-col>
								<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
									<div class="search-container">
										<div class="search-container-fn-input">
											<el-form-item label="发证日期：" prop="title_rec_date">
												<el-date-picker v-model="info.title_rec_date" type="date" size="small"
													v-if="type!=='view'" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
													placeholder="请选择发证日期">
												</el-date-picker>
												<span v-else>{{ info.title_rec_date }}</span>
											</el-form-item>
										</div>
									</div>
								</el-col>
							</el-row>
							<el-row :gutter="20">
								<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
									<div class="elUpload">
										<i v-if="type!=='view' && info.title_cert_list.code_page"
											@click="info.title_cert_list.code_page=''" class="el-icon-error del"></i>

										<el-upload v-if="type!=='view'" class="avatar-uploader" :disabled="type=='view'"
											:action="actionPath" :data="qiniuData" :show-file-list="false"
											:on-success="uploadZCCodePage" :before-upload="beforeAvatarUpload">
											<img v-if="info.title_cert_list &&  info.title_cert_list.code_page"
												:src="info.title_cert_list.code_page | filterCDNUrl" class="avatar">
											<i v-else class="el-icon-plus avatar-uploader-icon"></i>
										</el-upload>

										<el-image v-else :src="info.title_cert_list.code_page | filterCDNUrl"
											:z-index="9999"
											:preview-src-list="[ filterCDNUrl(info.title_cert_list.code_page )  ]"
											class="avatar"></el-image>

										<p>职称证编号页</p>
									</div>
								</el-col>

								<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
									<div class="elUpload">
										<i v-if="type!=='view' && info.title_cert_list.info_page"
											@click="info.title_cert_list.info_page=''" class="el-icon-error del"></i>

										<el-upload v-if="type!=='view'" class="avatar-uploader" :disabled="type=='view'"
											:action="actionPath" :data="qiniuData" :show-file-list="false"
											:on-success="uploadZCInfoPage" :before-upload="beforeAvatarUpload">
											<img v-if="info.title_cert_list  && info.title_cert_list.info_page"
												:src="info.title_cert_list.info_page | filterCDNUrl" class="avatar">
											<i v-else class="el-icon-plus avatar-uploader-icon"></i>
										</el-upload>
										<el-image v-else :src="info.title_cert_list.info_page | filterCDNUrl"
											:z-index="9999"
											:preview-src-list="[ filterCDNUrl(info.title_cert_list.info_page )  ]"
											class="avatar"></el-image>

										<p>职称证个人信息页</p>
									</div>

								</el-col>


							</el-row>
						</div>


						<!--            合同-->
						<div class="gg-grid">
							<div class="title">
								<h3>合同</h3>
							</div>
							<div style="width: 400px;">
								<el-upload v-if="type!=='view'" class="avatar-uploader" :disabled="type=='view'"
									:action="actionPath" :data="qiniuData" :show-file-list="false"
									:on-success="uploadHetongSuccess" :before-upload="beforeHetongUpload">
									<div v-if="type!='view'">
										<el-button size="small" type="primary">点击上传</el-button>
										<div slot="tip" class="el-upload__tip">仅支持上传最大8MB文件</div>
									</div>
								</el-upload>

								<div v-if="info.employ_files_list.length > 0">
									<ul style="margin: 0;padding: 0;">
										<li v-for="(item,index) in info.employ_files_list" :key="index"
											class="htList bbox ycenter xbetween">
											<a :href="item | filterCDNUrl" target="_blank">{{item}}</a>
											<i class="del ml-2" v-if="type!=='view'"
												@click="removeHetong(index)">&times;</i>
										</li>

									</ul>
								</div>
							</div>
						</div>

					</el-tab-pane>

					<el-tab-pane label="就诊设置" name="second" v-if="doctorType==1">
						<div class="gg-grid">
							<div class="title">
								<h3>图文复诊</h3>
							</div>
							<el-row :gutter="20">
								<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
									<div class="search-container">
										<div class="search-container-fn-input">
											<label>价格：</label>
											<el-input placeholder="请输入图文复诊价格" size="small"
												v-model="info.doctors_config.pic_price" v-if="type!=='view'"></el-input>
											<span v-else>{{ info.doctors_config.pic_price }}</span>
										</div>
									</div>
								</el-col>
							</el-row>
						</div>
						<div class="gg-grid">
							<div class="title">
								<h3>视频复诊</h3>
							</div>
							<el-row :gutter="20">
								<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
									<div class="search-container">
										<div class="search-container-fn-input">
											<label>价格：</label>
											<el-input placeholder="请输入视频复诊价格" v-model="info.doctors_config.video_price"
												size="small" v-if="type!=='view'"></el-input>
											<span v-else>{{ info.doctors_config.video_price }}</span>
										</div>
									</div>
								</el-col>
							</el-row>
						</div>
					</el-tab-pane>
					<el-tab-pane label="审核信息" name="third" v-if="type=='view' && info.examine_logs.length > 0">
						<el-timeline>
							<el-timeline-item v-for="(item,index) in info.examine_logs" :key="index"
								:timestamp="item.title + '  ' +item.examine_time" placement="top">
								<el-card>
									<div v-if="item.status_name && item.status_name!=''">
										<h4>审核结果</h4>
										<p> {{item.status_name}} </p>
									</div>
									<div v-if="item.remark">
										<h4>备注</h4>
										<p> {{item.remark}} </p>
									</div>
								</el-card>
							</el-timeline-item>

						</el-timeline>
					</el-tab-pane>
					<el-tab-pane label="擅长及简介" name="fourth">
						<div class="gg-grid">
							<el-row :gutter="20" v-if="doctorType==1">
								<el-col class="dashboard-container-col" :span="24">
									<div class="search-container" style="max-height: max-content">
										<div class="search-container-fn-input">
											<div style="width:200px;">
												<el-form-item label="科室：" class="flex-1" prop="depart_id">
													<el-select v-if="type!=='view'" v-model="info.depart_id" clearable
														placeholder="请选择" size="small" @change="departChange">
														<el-option v-for="(item,index) in departSymptom.departs"
															:key="index" :label="item.depart_name" :value="item.id">
														</el-option>
													</el-select>
													<span v-else>{{info.depart_name}}</span>
												</el-form-item>
											</div>


											<el-form-item class="ml-2" label="擅长疾病：" prop="professional">
												<el-select v-if="type!=='view'" clearable multiple style="width: 300px;"
													v-model="info.professional" placeholder="请选择" size="small">
													<el-option v-for="(item,index) in symptomList" :key="index"
														:label="item.symptom_name" :value="item.id">
													</el-option>
												</el-select>


												<div v-else>
													<span v-for="(span,index) in info.professional_name"
														:key="index">{{ span }} </span>
												</div>


											</el-form-item>
										</div>
									</div>
								</el-col>
							</el-row>
							<el-row :gutter="20">
								<el-col class="dashboard-container-col" :span="24">
									<div class="search-container" style="max-height: max-content">
										<div class="search-container-fn-input">
											<el-form-item label="个人简介：" prop="doc_comment">
												<el-input style="width:560px;" type="textarea"
													v-model="info.doc_comment " :autosize="{ minRows: 2, maxRows: 10}"
													placeholder="请输入个人简介" size="small" v-if="type!=='view'"></el-input>
											</el-form-item>
											<span v-if="type==='view'">{{ info.doc_comment }}</span>
										</div>
									</div>
								</el-col>
							</el-row>

						</div>
					</el-tab-pane>

					<el-tab-pane label="个人工作经历" name="five">
						<div class="gg-grid">
							<div class="title"
								style="display: flex;justify-content: space-between;align-items: center;">
								<h3>个人工作经历</h3>
								<el-button v-if="type!='view'" plain type="success" size="small" style="height: 30px;"
									icon="el-icon-plus" @click="addWork">
									新增经历
								</el-button>
							</div>

							<el-row :gutter="20">
								<el-col class="dashboard-container-col"
									v-for="(item,index) in info.work_experience_list" :key="index" :xs="24" :md="12">

									<el-card class="box-card mb-2">
										<el-button type="danger" class="delBtn" size="small" v-if="type!='view'"
											@click="delWork(index)">删除</el-button>
										<el-row :gutter="20">
											<el-col class="dashboard-container-col" :span="24">
												<div class="search-container">
													<div class="search-container-fn-input">
														<label>就职单位：</label>
														<el-input placeholder="请输入就职单位" v-model="item.company "
															size="small" v-if="type!=='view'"></el-input>
														<span v-else>{{ item.company }}</span>
													</div>
												</div>
											</el-col>
										</el-row>
										<el-row :gutter="20">
											<el-col class="dashboard-container-col" :span="24">
												<div class="search-container">
													<div class="search-container-fn-input">
														<label>就职时间：</label>
														<el-date-picker v-model="item.duration" size="small"
															type="datetimerange" format="yyyy-MM-dd"
															value-format="yyyy-MM-dd" range-separator="至"
															start-placeholder="开始日期" end-placeholder="结束日期"
															v-if="type!=='view'" align="left">
														</el-date-picker>
														<span v-else>{{ item.duration }}</span>
													</div>
												</div>
											</el-col>
										</el-row>
										<el-row :gutter="20">
											<el-col class="dashboard-container-col" :span="24">
												<div class="search-container">
													<div class="search-container-fn-input">
														<label>就职科室：</label>

														<el-select v-if="type!=='view'" v-model="item.department_id"
															clearable placeholder="请选择" size="small">
															<el-option v-for="(val,key) in departDic" :key="key"
																:label="val" :value="key">
															</el-option>
														</el-select>
														<span v-else>{{ item.depart_name }}</span>
													</div>
												</div>
											</el-col>
										</el-row>
										<el-row :gutter="20">
											<el-col class="dashboard-container-col" :span="24">
												<div class="search-container">
													<div class="search-container-fn-input">
														<label>职称：</label>
														<el-select v-if="type!=='view'" v-model="item.title_code"
															clearable placeholder="请选择" size="small">
															<el-option v-for="item in doctorDic.doctor_title"
																:key="item.value" :label="item.label"
																:value="item.value">
															</el-option>
														</el-select>
														<span v-else>{{ item.title_name }}</span>
													</div>
												</div>
											</el-col>
										</el-row>
										<el-row :gutter="20">
											<el-col class="dashboard-container-col" :span="24">
												<div class="search-container">
													<div class="search-container-fn-input">
														<label>证明人：</label>
														<el-input placeholder="请填写证明人" v-model="item.authenticator"
															size="small" v-if="type!=='view'"></el-input>
														<span v-else>{{ item.authenticator }}</span>
													</div>
												</div>
											</el-col>
										</el-row>
									</el-card>

								</el-col>
							</el-row>

						</div>
					</el-tab-pane>
					<el-tab-pane label="处罚或处分" name="six">
						<div class="gg-grid">
							<div class="title"
								style="display: flex;justify-content: space-between;align-items: center;">
								<h3>处罚或处分</h3>
								<el-button v-if="type!='view'" plain type="success" size="small" icon="el-icon-plus"
									@click="addPunish">
									新增
								</el-button>
							</div>

							<div v-for="(item,index) in info.punish_content" :key="index">
								<el-card class="box-card mb-2">
									<el-button type="dange" size="small" class="delBtn" v-if="type!='view'"
										@click="delPunish(index)">删除</el-button>
									<el-row :gutter="20">
										<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6"
											:xl="6">
											<div class="search-container">
												<div class="search-container-fn-input">
													<label>就职单位：</label>
													<el-input placeholder="请输入就职单位" v-model="item.company" size="small"
														v-if="type!=='view'"></el-input>
													<span v-else>{{ item.company }}</span>
												</div>
											</div>
										</el-col>
									</el-row>
									<el-row :gutter="20">
										<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6"
											:xl="6">
											<div class="search-container">
												<div class="search-container-fn-input">
													<label>惩罚/处分时间：</label>
													<el-date-picker v-model="item.punished_at" type="date" size="small"
														v-if="type!=='view'" format="yyyy-MM-dd"
														value-format="yyyy-MM-dd" placeholder="选择时间">
													</el-date-picker>
													<span v-else>{{ item.punished_at }}</span>
												</div>
											</div>
										</el-col>
									</el-row>
									<el-row :gutter="20">
										<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6"
											:xl="6">
											<div class="search-container">
												<div class="search-container-fn-input">
													<label>惩罚/处分原因：</label>
													<el-input placeholder="请输入惩罚/处分原因" v-model="item.reason"
														size="small" v-if="type!=='view'"></el-input>
													<span v-else>{{ item.reason }}</span>
												</div>
											</div>
										</el-col>
									</el-row>
									<el-row :gutter="20">
										<el-col class="dashboard-container-col" :xs="24" :sm="8" :md="8" :lg="6"
											:xl="6">
											<div class="search-container">
												<div class="search-container-fn-input">
													<label>处分结果：</label>
													<el-input placeholder="请输入处分结果" v-model="item.result" size="small"
														v-if="type!=='view'"></el-input>
													<span v-else>{{ item.result }}</span>
												</div>
											</div>
										</el-col>
									</el-row>
								</el-card>
							</div>


						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
			<!--        已认证-->
			<div class="gg-grid" v-if="admin_type==1">
				<div>
					<el-button type="success" plain v-if="type=='view'" @click="editDoctor">补充信息</el-button>
					<el-button type="danger" plain v-if="type=='view'">解约</el-button>
					<el-button type="warning" plain v-if="type=='view'" @click="uploadDoctorInfo">上传监管</el-button>
					<el-button type="primary" plain v-if="type!='view'" @click="saveForm('ruleForm')">保存</el-button>
					<el-button plain v-if="type!='view'" @click="type = 'view'">取消</el-button>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script>
	import {
		regionData
	} from 'element-china-area-data'
	import {
		mapState
	} from "vuex";
	import {
		callMethod,
		getOrgList,
		getQNToken
	} from "@/api/basic";
	import config from "@/utils/config";
	import {
		editDoctors,
		getDoctorsDetail,
		uploadDoctorInfo
	} from "@/api/audit/doctor";
	import {
		Message
	} from "element-ui";

	export default {
		name: "physicianDetail",
		props: {
			id: {
				type: [Number, String]
			},
			type: {
				type: String,
				default: 'add'
			},
			doctorType: {
				type: [Number, String],
				default: 1
			},
			status: {
				type: [Number, String],
				default: 1
			}
		},
		components: {},
		data() {
			return {
				admin_type: 0,
				actionPath: 'http://upload.qiniup.com',
				qiniuUrl: config.qiniuUrl,
				durationDate: [],
				symptomList: [],
				pcaOptions: regionData,
				qiniuData: {
					token: "",
					key: ""
				},
				fullscreenLoading: false,
				info: {
					doc_photo: '',
					employ_files_list: [],
					"id_card_list": {
						"a": "",
						"b": ""
					},
					"prac_scope_approval": [],
					"cert_doc_prac_list": {
						"code_page": "",
						"info_page": ""
					},

					"doc_cert_list": {
						"code_page": "",
						"info_page": ""
					},

					"title_cert_list": {
						"code_page": "",
						"info_page": ""
					},
					"badge_list": {
						"code_page": "",
						"info_page": ""
					},
					"professional": [],
					"work_experience_list": [],
					"examine_logs": [],
					"punish_content": [],
					"doctors_config": {},
				},
				activeTab: 'first',
				ruleForm: {
					info: {},
				},
				eduList: [
					// 中专、专科、本科、硕士研究生、博士研究生
					{
						value: '中专',
						label: '中专'
					},
					{
						value: '专科',
						label: '专科'
					},
					{
						value: '本科',
						label: '本科'
					},
					{
						value: '硕士研究生',
						label: '硕士研究生'
					},
					{
						value: '博士研究生',
						label: '博士研究生'
					},
				],
				rules: {
					doc_name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'change'
					}],
					doc_photo: [{
						required: true,
						message: '请上传证件照',
						trigger: 'change'
					}],
					organization_id: [{
						required: true,
						message: '请选择第一执业机构',
						trigger: 'change'
					}],
					ge_code: [{
						required: true,
						message: '请选择性别',
						trigger: 'change'
					}],
					nation_code: [{
						required: true,
						message: '请选择民族',
						trigger: 'change'
					}],
					doc_birthdate: [{
						required: true,
						message: '请选择出生日期',
						trigger: 'change'
					}],
					doc_edu: [{
						required: true,
						message: '请选择学历',
						trigger: 'change'
					}],
					doc_university: [{
						required: true,
						message: '请输入毕业院校',
						trigger: 'change'
					}],
					district_code: [{
						required: true,
						message: '请选择省市区',
						trigger: 'change'
					}],
					doc_address: [{
						required: true,
						message: '请输入详细地址',
						trigger: 'change'
					}],
					doc_postal_code: [{
						required: true,
						message: '请输入正确的邮政编码',
						trigger: 'change'
					}],
					depart_id: [{
						required: true,
						message: '请选择科室',
						trigger: 'change'
					}],
					professional: [{
						required: true,
						message: '请填写擅长疾病',
						trigger: 'blur'
					}],
					doc_comment: [{
						required: true,
						message: '请填写个人简介',
						trigger: 'blur'
					}],
					doc_tel: [{
						required: true,
						message: '请输入手机号',
						trigger: 'change'
					}],
					id_card: [{
						required: true,
						message: '请输入身份证号',
						trigger: 'change'
					}],

					prac_no: [{
						required: true,
						message: '请输入执业证号',
						trigger: 'change'
					}],
					// doc_multi_sited_date_end: [{required: true, message: '请选择执业证有效期', trigger: 'change'}],

					prac_level: [{
						required: true,
						message: '请选择执业级别',
						trigger: 'change'
					}],

					prac_type: [{
						required: true,
						message: '请选择执业类别',
						trigger: 'change'
					}],
					prac_scope_approval: [{
						required: true,
						message: '请选择执业范围',
						trigger: 'change'
					}],
					prac_rec_date: [{
						required: true,
						message: '请选择执业证取得时间',
						trigger: 'change'
					}],

					cert_no: [{
						required: true,
						message: '请输入资格证号',
						trigger: 'change'
					}],
					cert_rec_date: [{
						required: true,
						message: '请选择资格证取得时间',
						trigger: 'change'
					}],

					title_code: [{
						required: true,
						message: '请输入职称编码',
						trigger: 'change'
					}],
					title_name: [{
						required: true,
						message: '请输入职称名称',
						trigger: 'change'
					}],
					title_rec_date: [{
						required: true,
						message: '请选择职称证取得时间',
						trigger: 'change'
					}],

					desc_name: [{
							required: true,
							message: '请输入病情描述',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 50,
							message: '长度在 1 到 50 个字符',
							trigger: 'blur'
						}
					],

				},
				selectLoading: false,
				orgOptions: [],
			}
		},
		computed: {
			...mapState({
				departDic: state => state.dictionary.departDic,
				doctorDic: state => state.dictionary.doctorDic, //字典
				departSymptom: state => state.dictionary.departSymptom, //字典
			}),
			doctorTypeTitle() {
				return {
					1: '医师',
					2: '药师'
				} [this.doctorType] || '医生'
			},
			departSymptomList() {
				let arr = [...this.departSymptom.departs]
				// return this.departSymptom.departs
				for (let item of arr) {
					for (let sy of item.symptom) {
						sy.value = sy.id
						sy.depart_name = sy.symptom_name
					}
				}
				return arr
			}
		},
		mounted() {
			this.$store.dispatch('dictionary/getDicData', [{
				stateName: 'departDic',
				api: '/admin/setting/getDepartSelect'
			}]);
			this.$store.dispatch('dictionary/getDicData', [{
				stateName: 'doctorDic',
				api: '/admin/dicts?types[]=doctor_title&types[]=gender&types[]=nation&types[]=prac_level&types[]=prac_scope&types[]=prac_type'
			}]);
			this.$store.dispatch('dictionary/getDicData', [{
				stateName: 'departSymptom',
				api: '/admin/setting/getDepartSymptomSelect'
			}]);

		},
		created() {
			this.admin_type = this.$store.state.user.userType
			console.log('admin_type', this.admin_type)
			this.getToken()
			if (this.type !== 'add' && this.id) {
				//   获取医师或者药师详情
				this.getInfo()
			}
		},
		methods: {
			remoteMethod(query) {
				if (query !== '') {
					this.selectLoading = true;
					let params = {
						page_size: 999,
						keywords: query
					}
					getOrgList(params).then(res => {
						// eslint-disable-next-line no-debugger
						if (res.code == 200) {
							this.orgOptions = res.data.list
						} else {
							this.orgOptions = [];
						}
						this.selectLoading = false
					})
				} else {
					this.orgOptions = [];
				}
			},
			orgSelectChange(e) {
				console.log(e);
				this.info.organization_id = e
			},
			getInfo() {
				this.fullscreenLoading = true;
				let params = {
					id: this.id
				}

				getDoctorsDetail(params).then(response => {
					let data = response
					if (data.code == 200) {

						let _data = data.data
						this.info = {
							..._data
						}
						console.log(this.info, 8888)
					}
					if (this.info.depart_id) {
						this.info.depart_id = Number(this.info.depart_id)
					}
					this.fullscreenLoading = false
				}).catch(error => {
					Message({
						message: error.message,
						type: 'error'
					})
					this.fullscreenLoading = false
				})
			},
			getToken() {
				getQNToken().then(response => {
					let data = response
					if (data.code == 200) {
						this.qiniuData.token = data.data.token
					}
				}).catch(error => {
					console.log(error);
				})
			},
			handleChange(value) {
				this.info.district_code = value[2]
				this.info.city_code = value[1]
				this.info.province_code = value[0]
			},
			geChange(value) {
				for (let item of this.doctorDic.gender) {
					if (item.value == value) {
						this.info.ge_name = item.label
					}
				}
			},
			uploadDocPhotoSuccess(res) {
				this.info.doc_photo = res.key;
			},
			uploadIDCardA(res) {
				this.info.id_card_list.a = res.key;
			},
			uploadIDCardB(res) {
				this.info.id_card_list.b = res.key;
			},
			uploadCodePage(res) {
				this.info.cert_doc_prac_list.code_page = res.key;
			},
			uploadInfoPage(res) {
				this.info.cert_doc_prac_list.info_page = res.key;
			},
			uploadZGCodePage(res) {
				this.info.doc_cert_list.code_page = res.key;
			},
			uploadZGInfoPage(res) {
				this.info.doc_cert_list.info_page = res.key;
			},
			uploadZCCodePage(res) {
				this.info.title_cert_list.code_page = res.key;
			},
			uploadZCInfoPage(res) {
				this.info.title_cert_list.info_page = res.key;
			},
			uploadHetongSuccess(res) {
				this.info.employ_files_list.push(res.key);
			},
			beforeHetongUpload(file) {

				let name = new Date().getTime() + "_" + file.name //给file.name加上时间戳保证图片命名不重复
				console.log(name)
				this.qiniuData.key = name; // 通过设置key为文件名，上传到七牛中会显示对应的图片名
				// eslint-disable-next-line no-debugger
				// const isJPG = file.type == "application/pdf";
				const isLt10M = file.size / 1024 / 1024 < 8;
				//
				// if (!isJPG) {
				//   this.$message.error('上传合同只能是 pdf 格式!');
				// }
				if (!isLt10M) {
					this.$message.error('上传合同大小不能超过 8MB!');
				}
				return isLt10M;
			},
			beforeRemove(file) {
				return this.$confirm(`确定删除合同： ${ file.name }？`);
			},
			removeHetong(index) {
				// eslint-disable-next-line no-debugger
				this.info.employ_files_list.splice(index, 1)
			},
			//新增处罚
			addPunish() {
				let obj = {
					company: '',
					punished_at: '',
					reason: '',
					result: '',
				}
				this.info.punish_content.push(obj)
			},
			delPunish(index) {
				this.info.punish_content.splice(index, 1)
			},
			//新增处罚
			addWork() {
				let obj = {
					company: '',
					duration: '',
					department_id: '',
					title_name: '',
					authenticator: '',
				}
				this.info.work_experience_list.push(obj)
			},
			delWork(index) {
				this.info.work_experience_list.splice(index, 1)
			},
			beforeAvatarUpload(file) {
				let name = new Date().getTime() + "_" + file.name //给file.name加上时间戳保证图片命名不重复
				console.log(name)
				this.qiniuData.key = name; // 通过设置key为文件名，上传到七牛中会显示对应的图片名
				// debugger
				const isJPG = file.type === "image/jpeg" || file.type === "image/png";
				const isLt10M = file.size / 1024 / 1024 < 10;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt10M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt10M;
			},
			changeDepart(e) {
				this.info.department_id = e[0]
				this.info.symptom_id = e[1]

				const checkedNodes = this.$refs['cascaderDS'].getCheckedNodes()
				let nodes = checkedNodes[0].pathLabels
				this.info.department = nodes[0]
				this.info.symptom_name = nodes[1]
			},
			departChange(e) {
				this.info.professional = []
				for (let item of this.departSymptom.departs) {
					if (e == item.id) {
						this.symptomList = item.symptom
					}
				}
			},
			editDoctor() {
				this.type = 'edit'
				for (let item of this.departSymptom.departs) {
					if (this.info.depart_id == item.id) {
						this.symptomList = item.symptom
					}
				}
			},
			saveForm(form) {
				if (this.doctorType == 2) {
					delete this.info.doctors_config
				}
				this.$refs[form].validate((valid, object) => {
					if (!valid) {
						console.log(valid, object, 7777)
						let item;
						for (let key in object) {
							item = object[key][0]
							break
						}
						this.$message({
							type: 'error',
							message: item.message
						});
						return
					} else {
						console.log(valid, object, 666666)
						let params = {}
						let url = ''
						params = {
							...this.info
						}
						params.type = this.doctorType
						// params.doc_multi_sited_date_start = this.info.prac_rec_date
						if (this.type == 'add') {
							url = '/admin/doctors' //添加
							callMethod(params, url, 'post').then(response => {
								let data = response
								if (data.code == 200) {
									this.$message({
										type: 'success',
										message: '提交成功！'
									});

									this.$emit('editSuccess')
								} else {
									this.$message({
										type: 'error',
										message: data.message
									});
								}
								this.fullscreenLoading = false
							}).catch(error => {
								this.$message({
									type: 'error',
									message: error.response.data.message
								});
								this.fullscreenLoading = false
							})
						} else {
							//编辑
							delete params.professional_name
							delete params.examine_logs
							delete params.prac_level_name
							delete params.prac_type_name
							delete params.prac_scope_approval_name
							editDoctors(params).then(response => {
								let data = response
								if (data.code == 200) {
									this.$message({
										type: 'success',
										message: '新增成功！'
									});
								} else {
									this.$message({
										type: 'error',
										message: data.message
									});
								}
								this.fullscreenLoading = false
								this.$emit('editSuccess')
							}).catch(error => {
								this.$message({
									type: 'error',
									message: error.response.data.message
								});
								this.fullscreenLoading = false
							})
						}
						this.fullscreenLoading = true
					}
				})
			},
			uploadDoctorInfo() {
				//  上传监管
				let params = {
					doctor_id: this.info.id
				}
				this.fullscreenLoading = true
				uploadDoctorInfo(params).then((res) => {
					console.log(res);
					this.fullscreenLoading = false
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: '上传监管成功'
						});
					} else {
						this.$message({
							type: 'error',
							message: res.data[0] ? res.data[0] : res.message
						});
					}

				}).catch(error => {
					console.log(error);
					this.$message({
						type: 'error',
						message: '上传监管失败'
					});
					this.fullscreenLoading = false
				})
			},
			filterCDNUrl(url) {
				if (!url) {
					return ''
				} else {
					if (url.indexOf('http') > -1) {
						return url
					} else {
						return config.qiniuUrl + url
					}
				}
			}
		},
	}
</script>

<style scoped lang="scss">
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #CCC;
		padding-bottom: 20px;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #D9D9D9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8C939D;
		width: 298px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 298px;
		height: 178px;
		display: block;
	}

	.el-form-item {
		display: flex;
		margin-bottom: 0;
		align-items: center;
	}

	.elUpload {
		text-align: center;
		width: 298px;
		position: relative;

		.avatar-uploader {
			border: 1px dotted #666;
		}

		.el-upload {
			width: 100%;

			.avatar-uploader-icon {
				width: 100%;
			}
		}

		.avatar {
			max-width: 100%;
		}

		.del {
			position: absolute;
			top: 0;
			right: 0;
			color: #fff;
			font-size: 30px;
			color: red;
			cursor: pointer;
		}
	}

	.docPhoto {
		width: 160px;
	}

	.el-date-editor.el-input {
		width: auto;
	}

	.el-range-editor--small.el-input__inner {
		width: 100%;
	}

	.box-card {
		position: relative;

		.delBtn {
			position: absolute;
			top: 10px;
			right: 10px;
			z-index: 9;
		}
	}

	.search-container {
		max-height: 40px;
	}

	.htList {
		margin-top: 10px;
		background: #f0f0f0;
		border-radius: 5px;
		list-style: none;
		padding: 10px;
		position: relative;

		i {
			cursor: pointer;
		}
	}
</style>
