<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <!--输入框-->
      <div class="search-container-fn-input">
        <label>就诊人信息：</label>
        <el-input
          size="small"
          placeholder="就诊人姓名/就诊人电话"
          prefix-icon="el-icon-search"
          clearable
          v-model="searchParams.patient_keywords"
          @change="handleSearch('patient_keywords', searchParams.patient_keywords)"
        >
        </el-input>
      </div>
			<el-button
				size="mini"
				type="primary"
				style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
				@click="handleSearch('patient_keywords', searchParams.patient_keywords)"
			>
				<span>查找</span>
			</el-button>
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input" v-if="operateList.indexOf('examine') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
								background-color: #2362FB; border-color: #2362FB"
            @click="handleD(multipleSelectionPres[0], 'audit')"
            v-if="
              multipleSelectionPres.length == 1 &&
                multipleSelectionPres[0].examine_status == 0
            "
            >审核
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('examine') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
								background-color: #2362FB; border-color: #2362FB"
            @click="handleD(multipleSelectionPres[0], 'view')"
            v-if="
              multipleSelectionPres.length == 1
            "
            >查看
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
								  background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
								  background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="
                currUser = scope.row;
                getInfo(scope.row.doctors_id);
              "
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="
            column.field_type === 'image' &&
              column.visible === true &&
              column.column_label == '医师头像'
          "
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-avatar :src="scope.row.head_pic"></el-avatar>
          </template>
        </el-table-column> -->
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              v-if="
                column.column_prop == 'druggist_one_name' && admin_type != 2
              "
            >
              {{ scope.row.druggist_one_name }}
            </a>
            <a
              v-else-if="
                column.column_prop == 'druggist_second_name' && admin_type != 2
              "
            >
              {{ scope.row.druggist_second_name }}
            </a>
            <a v-else-if="column.column_prop == 'type'">
              {{ scope.row.type == 1 ? "成药开方" : "中药开方" }}
            </a>
            <a v-else-if="column.column_prop == 'is_show'">
              <el-switch
                active-text="展示"
                class="switch"
                inactive-text="隐藏"
                v-model="scope.row.is_show"
                active-color="#13ce66"
                inactive-color="#c8c8c8"
                :active-value="1"
                :inactive-value="0"
                @change="
                  institutionalStatus(scope.row.prescription_id, scope.row)
                "
              >
              </el-switch>
            </a>
            <a v-else>{{ scope.row[column.column_prop] }}</a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-select
                v-if="'examine_status_name' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(
                    column.column_prop,
                    searchParams.examine_status_name
                  )
                "
                v-model="searchParams.examine_status"
                clearable
                placeholder="请选择"
                size="small"
              >
                <template v-for="item in statusOptions">
                  <el-option
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-date-picker
                v-if="'second_audit_at' == column.column_prop"
                v-model="searchParams.examine_at"
                :default-time="['00:00:00', '23:59:59']"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="
                  handleSearch(column.column_prop, searchParams.second_audit_at)
                "
              >
              </el-date-picker>
              <el-date-picker
                v-if="'created_at' == column.column_prop"
                v-model="searchParams.created_at"
                :default-time="['00:00:00', '23:59:59']"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="
                  handleSearch(column.column_prop, searchParams.created_at)
                "
              >
              </el-date-picker>
              <el-select
                v-if="'source_name' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.source_name)
                "
                v-model="searchParams.source"
                clearable
                placeholder="请选择"
                size="small"
              >
                <template v-for="item in sourceList">
                  <el-option
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-select
                v-if="'agent_name' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.agent_name)
                "
                v-model="searchParams.agentid"
                clearable
                placeholder="请选择"
                size="small"
              >
                <template v-for="item in agents">
                  <el-option
                    :key="item.id"
                    :label="item.agent_name"
                    :value="item.id"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-select
                v-if="'gender' == column.column_prop"
                style="width: 200px"
                @change="handleSearch(column.column_prop, searchParams.gender)"
                v-model="searchParams.patient_gender"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in [
                    { label: '男', value: 1 },
                    { label: '女', value: 2 },
                  ]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-if="'pres_sn' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.pres_sn"
                clearable
                @change="handleSearch(column.column_prop, searchParams.pres_sn)"
              >
              </el-input>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!----------------分页---------------->
    <!--<pagination :total="total" :page.sync="listQuery.page" -->
    <!--						:limit.sync="listQuery.limit"-->
    <!--						@pagination="getList"/>-->

    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
    <el-drawer
      title="处方信息"
      size="76%"
      :visible.sync="dialogDrugsVisible"
      direction="rtl"
    >
      <!--药品清单审核弹窗-->
      <div style="margin-left:30px;">
        <el-descriptions title="处方信息" :column="2">
          <el-descriptions-item label="处方编号">{{
            currDrugs.pres_sn
          }}</el-descriptions-item>
          <el-descriptions-item label="科室">{{
            currDrugs.depart_name
          }}</el-descriptions-item>
          <el-descriptions-item label="姓名">{{
            currDrugs.patient_name
          }}</el-descriptions-item>
          <el-descriptions-item label="性别">
            <el-tag size="small">{{
              currDrugs.gender == 1 ? "男" : "女"
            }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="电话">{{
            currDrugs.mobile
          }}</el-descriptions-item>
          <el-descriptions-item
            :label="currDrugs.pres_type == 1 ? '诊断' : '辨病(或辨证)'"
            >{{
              currDrugs.pres_type == 1 ? currDrugs.diseases : currDrugs.diseases
            }}</el-descriptions-item
          >
          <el-descriptions-item label="审核状态"
            ><el-tag size="small">{{
              currDrugs.examine_status_name
            }}</el-tag></el-descriptions-item
          >
        </el-descriptions>
        <el-descriptions :column="2">
          <el-descriptions-item label="开方医师">{{
            currDrugs.doctor_name
          }}</el-descriptions-item>
          <el-descriptions-item label="审方医师">{{
            currDrugs.pharmacy_name
          }}</el-descriptions-item>
          <el-descriptions-item label="开方时间">{{
            currDrugs.created_at
          }}</el-descriptions-item>
          <el-descriptions-item label="审方时间">{{
            currDrugs.examine_at
          }}</el-descriptions-item>
          <el-descriptions-item label="处方笺"
            ><el-link type="primary" @click="innerVisible = true"
              >点击查看</el-link
            ></el-descriptions-item
          >
        </el-descriptions>
        <el-dialog
          width="70%"
          title="处方笺"
          :visible.sync="innerVisible"
          append-to-body
        >
          <el-image
            :src="currDrugs.pres_pic"
            fit="scale-down"
            :preview-src-list="[currDrugs.pres_pic]"
            :z-index="999999"
          ></el-image>
        </el-dialog>

        <h3>
          {{
            currDrugs.pres_type == 1
              ? "成药方子"
              : "中药方子 - " + currDrugs.dosage_name
          }}
        </h3>
        <template v-if="currDrugs.pres_type == 1">
          <el-table :data="currDrugs.drugs" border style="width: 100%">
            <el-table-column
              prop="drug_name"
              label="名称"
              width="170"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="sn" label="编号" align="center">
            </el-table-column>
            <el-table-column prop="specification" label="规格" align="center">
            </el-table-column>
            <el-table-column prop="dosage_unit" label="剂型" align="center">
            </el-table-column>
            <el-table-column prop="count" label="数量" align="center">
            </el-table-column>

            <el-table-column
              prop="specification"
              label="用药建议"
              align="center"
            >
              <template slot-scope="{ row }">
                {{ row.take_medicine }};{{ row.drug_method }}
              </template>
            </el-table-column>

            <el-table-column
              prop="specification"
              label="是否处方药"
              align="center"
            >
              <template slot-scope="{ row }">
                <el-tag size="small">{{ row.pres_not ? "是" : "否" }} </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-else>
          <el-descriptions :column="1" :labelStyle="{ width: '100px' }">
            <el-descriptions-item label="方子">
              <span
                style="padding: 0 6px"
                v-for="(item, index) in currDrugs.drugs"
                v-bind:key="index"
                >{{ item.drug_name }}{{ item.count
                }}{{ item.specification == "个" ? item.specification : "克"
                }}<el-tag v-if="item.fried_name" size="mini">{{
                  item.fried_name
                }}</el-tag></span
              >
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions
            :column="1"
            :labelStyle="{ width: '100px' }"
            v-if="currDrugs.assist && currDrugs.assist.length > 0"
          >
            <el-descriptions-item label="辅料">
              <span
                style="padding: 0 6px"
                v-for="(item, index) in currDrugs.assist"
                v-bind:key="index"
                >{{ item.assist_name }}{{ item.assist_count
                }}{{
                  item.specification == "个" ? item.specification : "克"
                }}</span
              >
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions :column="2" :labelStyle="{ width: '100px' }">
            <el-descriptions-item label="用药说明">
              {{ currDrugs.medication_method }};{{ currDrugs.medicine }}
            </el-descriptions-item>
            <el-descriptions-item label="服药禁忌">
              <span
                style="padding: 0 4px"
                v-for="(item, index) in currDrugs.taboo"
                v-bind:key="index"
                >{{ item }}</span
              >
            </el-descriptions-item>
            <el-descriptions-item label="医嘱">
              {{ currDrugs.doctor_advice }}
            </el-descriptions-item>
            <!-- <el-descriptions-item label="配药时间">
            {{ currDrugs.make_drug_time ? currDrugs.make_drug_time : "常规" }}
          </el-descriptions-item> -->
          </el-descriptions>
        </template>

        <div class="gg-grid" v-if="openType == 'audit'">
          <div class="auditForm">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="审核结果" prop="examine">
                <el-radio-group v-model="ruleForm.examine">
                  <el-radio label="Y">通过</el-radio>
                  <el-radio label="N">拒绝</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="拒绝原因"
                prop="reject_reason"
                v-if="ruleForm.examine == 'N'"
              >
                <el-input
                  type="textarea"
                  v-model="ruleForm.reject_reason"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  size="small"
                  @click="submitForm('ruleForm')"
                  >审核</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <!-- </el-dialog> -->
    </el-drawer>
    <el-dialog
      title="药师详情"
      :visible.sync="dialogTableVisible"
      v-if="dialogTableVisible"
      :fullscreen="true"
    >
      <physicianDetail doctorType="2" type="view" :id="ys_id"></physicianDetail>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import { mapState } from "vuex";
import {
  getAgentOptions,
  getPrescriptions,
  prescriptions,
  getPrescriptionList,
  reviseState,
  presInfo,
} from "@/api/order/index";
import physicianDetail from "@/views/doctorManage/physicianDetail";
import editTableHead from "@/components/editTableHead/editTableHead";
import { getAdminFieldIndex } from "@/api/drugs2.0";
import { Message } from "element-ui";

export default {
  name: "recipeAudit",
  computed: {
    ...mapState({
      orderStatus: (state) => state.dictionary.orderStatus,
      pres_switch: (state) => state.user.pres_switch,
      table_options: (state) => state.user.table_options,
    }),
  },
  async created() {
    this.admin_type = this.$store.state.user.userType;
    await this._getAdminFieldIndex();
    this.getList();
    // this.getOrderSource();
    this.agentOptions()
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
    //系统字典
    this.$store.dispatch("dictionary/getOrderStatus");
    console.log(this, "this");
  },
  data() {
    return {
      agents:[],
      table_type:"recipeAudit",
      operateList: [],
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      multipleSelection: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "pres_sn",
          isSearch: false,
        },
        {
          name: "gender",
          isSearch: false,
        },
        {
          name: "examine_status_name",
          isSearch: false,
        },
        {
          name: "source_name",
          isSearch: false,
        },
        {
          name: "agent_name",
          isSearch: false,
        },
        {
          name: "created_at",
          isSearch: false,
        },
        {
          name: "second_audit_at",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      innerVisible: false,
      ys_id: 0,
      dialogTableVisible: false,
      sourceList: [],
      admin_type: 0,
      searchParams: {
        orderTime: [],
      },
      medicineList: {},
      desc_pic: [],
      openType: "",
      type: "",
      currDrugs: {
        details: [],
      },
      dialogDrugsVisible: false,
      total: 0,
      listLoading: true,
      datetimerange: [],
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      ruleForm: {
        examine: "",
      },
      statusOptions: [
        {
          label: "待审核",
          value: 0,
        },
        {
          label: "审核通过",
          value: 1,
        },{
          label: "审核拒绝",
          value: 3,
        }
        // {label:'已拒绝',value:3},
      ],
      rules: {
        examine: [
          {
            required: true,
            message: "请选择审核结果",
            trigger: "change",
          },
        ],
      },
      tableData: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      fullscreenLoading: false,
    };
  },
  components: {
    Pagination,
    physicianDetail,
    // eslint-disable-next-line vue/no-unused-components
    editTableHead,
  },

  methods: {
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "recipeAudit",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.recipeAudit;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    //审核
    handleD(row, type) {
      this.listLoading = true;
      presInfo(row.prescription_id)
        .then((res) => {
          this.listLoading = false;
          if (res.code == 200) {
            console.log(3333, res.data);
            this.currDrugs = res.data;
            this.openType = type;
            this.medicineList = res.data;
            this.dialogDrugsVisible = true;
          }
        })
        .catch((err) => {
          this.listLoading = false;
        });
    },
    //修改状态
    institutionalStatus(id, row) {
      let params = {
        is_show: row.is_show,
      };
      reviseState(id, params)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("状态修改成功");
          } else {
            if (row.is_show == "0") {
              console.log(111, this.tableData);
              row.is_show = "1";
            }
            if (row.is_show == "1") {
              console.log(222);
              row.is_show = "0";
            }
            this.$message.error(res.message ? res.message : res.msg);
          }
        })
        .catch((err) => {
          if (row.is_show == "0") {
            row.is_show = "1";
          }
          if (row.is_show == "1") {
            row.is_show = "0";
          }
          this.$message.error(err.message ? err.message : err.msg);
        });
    },
    //获取来源选项
    // getOrderSource() {
    //   getOrderSource().then((res) => {
    //     if (res && res.code == 200) {
    //       this.sourceList = res.data;
    //     }
    //   });
    // },
    agentOptions() {
      getAgentOptions().then((res) => {
        if (res && res.code == 200) {
          this.agents = res.data;
        }
      });
    },
    hadlePhay(id) {
      if (this.admin_type == 2) {
        this.$router.push({
          path: "/auditManage/pharmacistInfo",
          query: {
            _type: "view",
            _id: id,
          },
        });
      } else {
        this.$router.push({
          path: "/doctorManage/pharmacistManage",
          query: {
            _type: "view",
            _id: id,
          },
        });
      }
    },

    getList(type) {
      if (type == "restPage") {
        this.listQuery.page = 1;
        console.log("getList触发22222");
      }
      console.log("getList触发");
      this.listLoading = true;
      this.searchParams.page = this.listQuery.page;
      this.searchParams.page_size = this.listQuery.limit;

      getPrescriptionList(this.searchParams)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.list;
          }
          this.fullscreenLoading = false;
          this.listLoading = false;
        })
        .catch((error) => {
          Message({
            message: error.message,
            type: "error",
          });
          this.listLoading = false;
          this.fullscreenLoading = false;
        });
    },
    reset() {
      // this.searchParams = {};
      this.listQuery.page = 1;
      this.getList();
    },
    dateChange(val) {
      if (val && val.length) {
        this.searchParams.created_at = JSON.stringify(val);
      } else {
        this.searchParams.created_at = [];
      }
      this.getList();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            ...this.ruleForm,
          };
          params.id = this.currDrugs.prescription_id;
          this.fullscreenLoading = true;
          prescriptions(params)
            .then((response) => {
              let data = response;
              if (data.code == 200) {
                let _data = data.data;
                console.log(_data);
                Message({
                  message: "审核成功",
                  type: "success",
                });
                this.dialogDrugsVisible = false;
                this.getList();
              } else {
                Message({
                  message: data.message ? data.message : data.msg,
                  type: "error",
                });
                this.dialogDrugsVisible = false;
              }
              this.fullscreenLoading = false;
              this.listLoading = false;
            })
            .catch((error) => {
              console.log(error);
              Message({
                message: error.message ? error.message : error.msg,
                type: "error",
              });
              this.listLoading = false;
              this.fullscreenLoading = false;
              this.dialogDrugsVisible = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
/deep/.switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}

/deep/.switch {
  margin-left: 8px;
}

/*打开时文字位置设置*/
/deep/.switch .el-switch__label--right {
  z-index: 1;
}

/* 调整打开时文字的显示位子 */
/deep/.switch .el-switch__label--right span {
  margin-left: -2px;
}

/*关闭时文字位置设置*/
/deep/.switch .el-switch__label--left {
  z-index: 1;
}

/* 调整关闭时文字的显示位子 */
/deep/.switch .el-switch__label--left span {
  margin-left: 22px;
}

/*显示文字*/
/deep/.switch .el-switch__label.is-active {
  display: block;
}

/* 调整按钮的宽度 */
/deep/.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
  margin: 0;
}
.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 30px;
}
.font-mar {
  margin-right: 20px;
}
</style>
